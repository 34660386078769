<template>
  <div>
    <b-modal
      v-model="model_addhp"
      id="modal-center"
      centered
      title="เพิ่ม Device"
      ok-only
      ok-variant="danger"
      ok-title="ยกเลิก"
    >
      <b-row>
        <!-- Mac -->
        <b-col md="12" xl="12">
          {{ name_beach }}
          <b-form-group label="Mac" label-for="mac">
            <cleave
              id="mac"
              v-model="mac"
              class="form-control"
              :raw="false"
              :options="options.customDelimiter"
              placeholder="XX : XX : XX : XX : XX"
            />
          </b-form-group>
        </b-col>
        <!-- Description -->
        <b-col md="12" xl="12">
          <b-form-group label="Description" label-for="description">
            <b-form-textarea
              id="description"
              v-model="description"
              class="form-control"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <br /><br />
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="save()"
          >
            บันทึก
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BCardCode,
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    Cleave,
    BRow,
    BCol,
    BFormTextarea,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      model_addhp: false,
      mac: "",
      description: "",
      name_beach: "",
      id_beach: "",
      options: {
        customDelimiter: {
          delimiters: ["-", "-", "-", "-", "-"],
          blocks: [2, 2, 2, 2, 2, 2],
          uppercase: true,
        },
      },
      check: {
        mac: false,
      },
      store_id: localStorage.getItem("store_id"),
    };
  },
  mounted() {},
  computed: {},
  methods: {
    showmodeladdHP(id_beach, name_beach, hotspot_total, hotspot) {
      this.mac = null;
      this.model_addhp = true;
      console.log("add_hp id_beach :>> ", id_beach);
      console.log("add_hp name_beach :>> ", name_beach);
      console.log("add_hp hotspot_total :>> ", hotspot_total);
      console.log("add_hp hotspot :>> ", hotspot);
      this.id_beach = id_beach;
      this.name_beach = name_beach;
    },
    save() {
      var userData = JSON.parse(localStorage.getItem("userData"));
      if (this.mac) {
        console.log("mac", this.mac);
        console.log("description", this.description);
        console.log("id_beach", this.id_beach);
        console.log("store_id", this.store_id);
        this.$swal({
          title: "คุณต้องการเพิ่ม Device?",
          text: this.name_beach,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "บันทึก!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            let formData = new FormData();
            formData.append("store_id", this.store_id);
            formData.append("id_beach", this.id_beach);
            formData.append("mac", this.mac);
            formData.append("description", this.description);
            formData.append("user_main", userData.user_main);
            const params = {
              store_id: this.store_id,
              id_beach: this.id_beach,
              mac: this.mac,
              description: this.description,
            };
            console.log("params :loaddata_store_id>> ", params);
            api
              .post("add_hp", formData, {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                console.log(response.data);
                if (response.data == 500) {
                  this.$swal({
                    icon: "error",
                    title: "เพิ่ม Device ไม่สำเร็จ " + this.mac,
                    text: "ตรวจสอบข้อมูลใหม่อีกครั้ง",
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                  });
                } else {
                  this.refresh();
                  this.mac = "";
                  this.description = "";
                  this.id_beach = "";
                  this.model_addhp = false;
                  this.$swal({
                    icon: "success",
                    title: "เพิ่ม Device สำเร็จ " + this.mac,
                    text: this.name_beach,
                    timer: 1500,
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                  });
                }
              });
          }
        });
      } else {
        if (!this.mac) {
          this.check.mac = true;
          console.log("mac null");
        }

        this.$swal({
          title: "ตรวจสอบข้อมูลใหม่อีกครั้ง",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
      }
    },
    refresh() {
      this.$emit("loaddata_brach_inby_store", "loaddata_brach_inby_store");
    },
  },
};
</script>
